/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES (INITIALIZATION) ----------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- IMPORTS ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

import * as faceapi from "face-api.js";

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- VARIABLES ---------------------------------- */
/* -------------------------------------------------- */
/* -------------------------------------------------- */



export const MODEL_URL = 'face-api-models';
export const SSD_MOBILENETV1 = 'ssd_mobilenetv1';
export const TINY_FACE_DETECTOR = 'tiny_face_detector';

export let selectedFaceDetector = SSD_MOBILENETV1;

// ssd_mobilenetv1 options
export let minConfidence = 0.5;

// tiny_face_detector options
export let inputSize = 512;
export let scoreThreshold = 0.5;

/* -------------------------------------------------- */
/* -------------------------------------------------- */
/* ----- GENERAL ------------------------------------ */
/* -------------------------------------------------- */
/* -------------------------------------------------- */

export function rgb2hsv( r, g, b )
{
    let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn;
    rabs = r / 255;
    gabs = g / 255;
    babs = b / 255;
    v = Math.max(rabs, gabs, babs),
        diff = v - Math.min(rabs, gabs, babs);
    diffc = c => (v - c) / 6 / diff + 1 / 2;
    percentRoundFn = num => Math.round(num * 100) / 100;

    if( diff == 0 ) h = s = 0;
    else
    {
        s = diff / v;
        rr = diffc( rabs );
        gg = diffc( gabs );
        bb = diffc( babs );

        if( rabs === v ) h = bb - gg;
        else if( gabs === v ) h = (1 / 3) + rr - bb;
        else if( babs === v ) h = (2 / 3) + gg - rr;

        if( h < 0 ) h += 1;
        else if( h > 1 ) h -= 1;
    }

    return {
        h: Math.round( h * 360 ),
        s: percentRoundFn( s * 100 ),
        v: percentRoundFn( v * 100 )
    };
}


export function filterSkin( data )
{

    for( var i = 0; i < data.length; i += 4 )
    {
        var result = rgb2hsv( data[ i ], data[ i + 1 ], data[ i + 2 ] );

        if( !( ( ( 0.0 <= result.h && result.h <= 50.0 ) ) && 23 <= result.s && result.s <= 68  &&
            data[ i ] > 95 && data[ i + 1 ] > 40 && data[ i + 2 ] > 20 && data[ i ] > data[ i + 1 ] &&
            data[ i ] > data[ i + 2 ] && ( data[ i ] - data[ i + 1 ] ) > 15 && data[ i + 3 ] > 15 ) )
        {

            data[ i ] = 0;
            data[ i + 1 ] = 0;
            data[ i + 2 ] = 0;
        }
    }

    return data;
}

export const buildRgb = (imageData) => {
	const rgbValues = [];
	// note that we are loopin every 4!
	// for every Red, Green, Blue and Alpha
	for (let i = 0; i < imageData.length; i += 4) {
		const rgb = {
			r: imageData[i],
			g: imageData[i + 1],
			b: imageData[i + 2],
		};

		rgbValues.push(rgb);
	}

	return rgbValues;
};

// Convert each pixel value ( number ) to hexadecimal ( string ) with base 16
export const RGBToHex = (pixel) => {
	const componentToHex = (c) => {
		const hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	};

	return (
		"#" +
		componentToHex(pixel.r) +
		componentToHex(pixel.g) +
		componentToHex(pixel.b)
		).toUpperCase();
};

/**
 * Converts a hex color code to an RGB color object.
 * @param {string} hex - The hex color code to convert.
 * @returns {object} An object with the red, green, and blue components of the RGB color.
 */
export const hexToRGB = ( hex ) => {
	// Remove the # character from the beginning of the hex code
	hex = hex.replace("#", "");

	// Convert the red, green, and blue components from hex to decimal
	// you can substring instead of slice as well
	const r = parseInt( hex.slice( 0, 2 ), 16 );
	const g = parseInt( hex.slice( 2, 4 ), 16 );
	const b = parseInt( hex.slice( 4, 6 ), 16 );

	// Return the RGB value as an object with properties r, g, and b
	return { r, g, b };
}

/**
 * Convert HSL to Hex
 * this entire formula can be found in stackoverflow, credits to @icl7126 !!!
 * https://stackoverflow.com/a/44134328/17150245
 */
export const hslToHex = (hslColor) => {
	const hslColorCopy = { ...hslColor };
	hslColorCopy.l /= 100;
	const a =
	(hslColorCopy.s * Math.min(hslColorCopy.l, 1 - hslColorCopy.l)) / 100;
	const f = (n) => {
		const k = (n + hslColorCopy.h / 30) % 12;
		const color = hslColorCopy.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
		.toString(16)
		.padStart(2, "0");
	};
	return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
};

/**
 * Convert RGB values to HSL
 * This formula can be
 * found here https://www.niwa.nu/2013/05/math-behind-colorspace-conversions-rgb-hsl/
 */
export const convertRGBtoHSL = (rgbValues) => {
	return rgbValues.map((pixel) => {
		let hue,
		saturation,
		luminance = 0;

		// first change range from 0-255 to 0 - 1
		let redOpposite = pixel.r / 255;
		let greenOpposite = pixel.g / 255;
		let blueOpposite = pixel.b / 255;

		const Cmax = Math.max(redOpposite, greenOpposite, blueOpposite);
		const Cmin = Math.min(redOpposite, greenOpposite, blueOpposite);

		const difference = Cmax - Cmin;

		luminance = (Cmax + Cmin) / 2.0;

		if (luminance <= 0.5) {
			saturation = difference / (Cmax + Cmin);
		} else if (luminance >= 0.5) {
			saturation = difference / (2.0 - Cmax - Cmin);
		}

	    /**
	     * If Red is max, then Hue = (G-B)/(max-min)
	     * If Green is max, then Hue = 2.0 + (B-R)/(max-min)
	     * If Blue is max, then Hue = 4.0 + (R-G)/(max-min)
	     */
		const maxColorValue = Math.max(pixel.r, pixel.g, pixel.b);

		if (maxColorValue === pixel.r) {
			hue = (greenOpposite - blueOpposite) / difference;
		} else if (maxColorValue === pixel.g) {
			hue = 2.0 + (blueOpposite - redOpposite) / difference;
		} else {
			hue = 4.0 + (greenOpposite - blueOpposite) / difference;
		}

	    hue = hue * 60; // find the sector of 60 degrees to which the color belongs

	    // it should be always a positive angle
	    if (hue < 0) {
	    	hue = hue + 360;
	    }

	    // When all three of R, G and B are equal, we get a neutral color: white, grey or black.
	    if (difference === 0) {
	    	return false;
	    }

	    return {
			h: Math.round(hue) + 180, // plus 180 degrees because that is the complementary color
			s: parseFloat(saturation * 100).toFixed(2),
			l: parseFloat(luminance * 100).toFixed(2),
		};
	});
};

/**
* Calculate the color distance or difference between 2 colors
*
* further explanation of this topic
* can be found here -> https://en.wikipedia.org/wiki/Euclidean_distance
* note: this method is not accuarate for better results use Delta-E distance metric.
*/
export const calculateColorDifference = (color1, color2) => {
	const rDifference = Math.pow(color2.r - color1.r, 2);
	const gDifference = Math.pow(color2.g - color1.g, 2);
	const bDifference = Math.pow(color2.b - color1.b, 2);

	return rDifference + gDifference + bDifference;
};

// returns what color channel has the biggest difference
export const findBiggestColorRange = (rgbValues) => {
	/**
	* Min is initialized to the maximum value posible
	* from there we procced to find the minimum value for that color channel
	*
	* Max is initialized to the minimum value posible
	* from there we procced to fin the maximum value for that color channel
	*/
	let rMin = Number.MAX_VALUE;
	let gMin = Number.MAX_VALUE;
	let bMin = Number.MAX_VALUE;

	let rMax = Number.MIN_VALUE;
	let gMax = Number.MIN_VALUE;
	let bMax = Number.MIN_VALUE;

	rgbValues.forEach((pixel) => {
		rMin = Math.min(rMin, pixel.r);
		gMin = Math.min(gMin, pixel.g);
		bMin = Math.min(bMin, pixel.b);

		rMax = Math.max(rMax, pixel.r);
		gMax = Math.max(gMax, pixel.g);
		bMax = Math.max(bMax, pixel.b);
	});

	const rRange = rMax - rMin;
	const gRange = gMax - gMin;
	const bRange = bMax - bMin;

	// determine which color has the biggest difference
	const biggestRange = Math.max(rRange, gRange, bRange);
	if (biggestRange === rRange) {
		return "r";
	} else if (biggestRange === gRange) {
		return "g";
	} else {
		return "b";
	}
};

/**
 * Median cut implementation
 * can be found here -> https://en.wikipedia.org/wiki/Median_cut
 */
export const quantization = (rgbValues, depth) => {
	const MAX_DEPTH = 4;

	// Base case
	if (depth === MAX_DEPTH || rgbValues.length === 0) {
		const color = rgbValues.reduce(
			(prev, curr) => {
				prev.r += curr.r;
				prev.g += curr.g;
				prev.b += curr.b;

				return prev;
			},
			{
				r: 0,
				g: 0,
				b: 0,
			}
			);

		color.r = Math.round(color.r / rgbValues.length);
		color.g = Math.round(color.g / rgbValues.length);
		color.b = Math.round(color.b / rgbValues.length);

		return [color];
	}

	/**
	*  Recursively do the following:
	*  1. Find the pixel channel (red,green or blue) with biggest difference/range
	*  2. Order by this channel
	*  3. Divide in half the rgb colors list
	*  4. Repeat process again, until desired depth or base case
	*/
	const componentToSortBy = findBiggestColorRange(rgbValues);
	rgbValues.sort((p1, p2) => {
		return p1[componentToSortBy] - p2[componentToSortBy];
	});

	const mid = rgbValues.length / 2;
	return [
		...quantization(rgbValues.slice(0, mid), depth + 1),
		...quantization(rgbValues.slice(mid + 1), depth + 1),
		];
};

export const averageRGB = (function () {

	// Keep helper stuff in closures
	var reSegment = /[\da-z]{2}/gi;

	// If speed matters, put these in for loop below
	function dec2hex(v) {return v.toString(16);}
	function hex2dec(v) {return parseInt(v,16);}

	return function (c1, c2) {

		// Split into parts
		var b1 = c1.match(reSegment);
		var b2 = c2.match(reSegment);
		var t, c = [];

		// Average each set of hex numbers going via dec always rounds down
		for (var i=b1.length; i;) {
			t = dec2hex( (hex2dec(b1[--i]) + hex2dec(b2[i])) >> 1 );

		// Add leading zero if only one character
			c[i] = t.length == 2? '' + t : '0' + t; 
		}
		return  c.join('');
	}
}());

/**
 * Finds the color in the given array that is closest to the target color.
 * @param {string} targetColor - The target color in hex string format (#RRGGBB).
 * @param {string[]} colorArray - An array of colors to compare against the target color.
 * @returns {string} The color in the array that is closest to the target color.
 */
export const closestColor = ( targetColor, colorArray ) => {
	let closestDistance = null;
	let closestColor = null;

	// Convert target color from hex string to RGB values
	// const [r1, g1, b1] = hexToRGB( targetColor );
	const targetArray1 = hexToRGB( targetColor );
	const r1 = targetArray1.r, g1 = targetArray1.g, b1 = targetArray1.b;

	// Loop through the array of colors
	colorArray.forEach( ( color ) => {
		// Convert current color from hex string to RGB values
		// const [ r2, g2, b2 ] = hexToRGB( color );
		const targetArray2 = hexToRGB( color );
		const r2 = targetArray2.r, g2 = targetArray2.g, b2 = targetArray2.b;

		// Calculate the Euclidean distance between the target color and current color
		const distance = Math.sqrt(
			( r1 - r2 ) ** 2 +
			( g1 - g2 ) ** 2 +
			( b1 - b2 ) ** 2
			);

		// Update closest color and distance if the current distance is smaller than the closest distance
		if( closestDistance == null || distance < closestDistance )
		{
			closestDistance = distance;
			closestColor = color;
		}
	});

	return closestColor;
}

export function getFaceDetectorOptions()
{
	return selectedFaceDetector === SSD_MOBILENETV1
	? new faceapi.SsdMobilenetv1Options({ minConfidence })
	: new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold });
}

export function getCurrentFaceDetectionNet()
{
	if (selectedFaceDetector === SSD_MOBILENETV1) {
		return faceapi.nets.ssdMobilenetv1;
	}
	if (selectedFaceDetector === TINY_FACE_DETECTOR) {
		return faceapi.nets.tinyFaceDetector;
	}
}

export function isFaceDetectionModelLoaded()
{
	return !!getCurrentFaceDetectionNet().params;
}

export async function changeFaceDetector(detector)
{
	selectedFaceDetector = detector
	if (!isFaceDetectionModelLoaded()) {
		await getCurrentFaceDetectionNet().load( MODEL_URL );
	}
}





